.ck.ck-reset.ck-editor.ck-rounded-corners:focus-within .ck.ck-editor__top.ck-reset_all {
	display: block;
}

.ck.ck-editor__top.ck-reset_all {
	position: sticky;
	top: calc(var(--navbar-height) + 40px);
	display: none;
	z-index: 1020;
}
.ck.ck-sticky-panel__content.ck-sticky-panel__content_sticky {
	position: static!important;
}
.ck.ck-sticky-panel {
	position: absolute!important;
	bottom: 0;
	right: 0;
	left: 0;
}
.ck.ck-sticky-panel__content {
	box-shadow: var(--ck-drop-shadow),0 0;

}

.ck.ck-reset.ck-editor.ck-rounded-corners.ck-plugin-full-screen .ck.ck-editor__top.ck-reset_all {
	position: static;
	top: 120px;
	display: block;
}
.ck.ck-reset.ck-editor.ck-rounded-corners.ck-plugin-full-screen .ck.ck-sticky-panel {
	position: static!important;
}


.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
	min-height: 200px;
}

/** 下拉菜单高度限制 */
.ck.ck-font-family-dropdown .ck-dropdown__panel, .ck.ck-font-size-dropdown .ck-dropdown__panel{
	max-height: 50vh;
	max-width: 50vw;
	overflow-y: auto;
}
/** 以下是自定义全屏处理的样式 */


.ck-editor.ck-plugin-full-screen {
	margin-top: var(--navbar-height) !important;
	height: calc(100vh - var(--navbar-height)) !important
}

.ck.ck-dropdown {
	z-index: 1 !important;
}

.ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
	z-index: 1 !important;
}

:root{
	--ck-z-default: 99999 !important;
}
.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck.ck-content.ck-rounded-corners {
	border-radius: var(--border-radius)!important;
}
